.UnformattedTextInfo {
  visibility: hidden;
}

.DownloadButton {
  cursor: pointer;
  border: 1px solid var(--mb-color-border);
  border-radius: 0.5rem;
  background: var(--mb-color-background);
  color: var(--mb-color-text-primary);
}

.DownloadButton:hover {
  border-color: var(--mb-color-background-hover);
  background: var(--mb-color-background-hover);
  color: var(--mb-color-text-hover);

  & .UnformattedTextInfo {
    visibility: visible;
  }
}
